import './css/App.css';
import Portfolio from './components/pages/Portfolio.js';
import React from 'react';
import {DataProvider} from './GlobalState.js'


function App() {

  return (
    <React.Fragment>
      <DataProvider>
        <Portfolio />
      </DataProvider>
    </React.Fragment>
  );
}

export default App;
