import React, { useState, useEffect, useRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import { DateTime } from "luxon";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { ethers } from "ethers";
import { configs } from '../../blockchain/web3.configs'
import MetaMaskOnboarding from '@metamask/onboarding';
import Multiselect from 'multiselect-react-dropdown';
import Footer from "../layout/footer";
import defaultPreviewImage from '../../assets/images/nft-preview-placeholder.png'

const imageTypes = ["JPG", "PNG", "GIF", "JPEG"];
const videoTypes = ["MP4", "MOV", "WMV", "AVI"]
const audioTypes = ["MP3", "AAC", "WAV"]
const thrdTypes = ["OBJ", "CSO", "VS", "HXN", "FX"]


export default function Nft() {

    const [file, setFile] = useState(null)
    const [imageButtonState, setImageButtonState] = useState(true)
    const [videoButtonState, setVideoButtonState] = useState(false)
    const [musicButtonState, setMusicButtonState] = useState(false)
    const [thrdButtonState, set3dButtonState] = useState(false)
    const [uploadFileType, setUploadFileType] = useState(imageTypes)
    const [filetype, setFileType] = useState("image")
    const [name, setName] = useState('')
    const [description, setDescription] = useState("")
    const [isNftMintingLoading, setIsNftMintingLoading] = useState(false)
    const [categories, setCategories] = useState([{ 'name': 'Art', 'id': 1 }, { 'name': 'Cartoon', 'id': 2 }, { 'name': 'Music', 'id': 3 }, { 'name': '3D', 'id': 4 }])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [nftImagePreview, setNftImagePreview] = useState('')

    const [accounts, setAccounts] = useState([]);

    const multiSelectRef = useRef(null)

    useEffect(() => {
        function handleNewAccounts(newAccounts) {
            setAccounts(newAccounts);
        }
        if (MetaMaskOnboarding.isMetaMaskInstalled()) {
            window.ethereum
                .request({ method: 'eth_requestAccounts' })
                .then(handleNewAccounts);
            window.ethereum.on('accountsChanged', handleNewAccounts);
            return () => {
                window.ethereum.off('accountsChanged', handleNewAccounts);
            };
        }
    }, []);


    const resetFormData = () => {
        setFileType("image")
        setName("")
        setDescription("")
        setSelectedCategories([])
        setNftImagePreview("")
        setFile(null)
        multiSelectRef.current.resetSelectedValues()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            // form validations
            if (file === null) {
                toast.error('Oppz! No files have uploaded', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return
            }

            if (name === '') {
                toast.error('Oppz! Name is required', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return
            }

            if (selectedCategories.length === 0) {
                toast.error('Oppz! you must select atleast one category', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return
            }

            if (description === '') {
                toast.error('Oppz! Description is required', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return
            }

            setIsNftMintingLoading(true)
            //upload the file
            const IPFSHash = await uploadFileToIPFS(file)
            //create metadata file
            const metadataPayload = createMetaDataFile(IPFSHash)
            //upload metadata file to the IPFS server
            const metadataResponse = await uploadMetaDataFile(metadataPayload)
            const metaDataURI = "ipfs://" + metadataResponse.IpfsHash;

            //TODO :: execute minting function in the contract
            const provider = new ethers.providers.JsonRpcProvider(
                'https://bsc-dataseed.binance.org/'
            );
            const { nftContractConfig } = configs;
            //create nft minting contract instance 
            let everEarnNFTMintingContract = new ethers.Contract(
                nftContractConfig.contractAddress,
                nftContractConfig.contractABI,
                provider
            );

            if (accounts.length > 0) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                let signer = provider.getSigner(accounts[0]);
                let contractWithSigner = everEarnNFTMintingContract.connect(signer);
                let transactionResponse = await contractWithSigner.safeMint(accounts[0], metaDataURI, {
                    from: accounts[0],
                    gasLimit: nftContractConfig.estimatedGasFeeLimit.toString(),
                    value: nftContractConfig.constInWeiForSingleMint,
                });

                const receipt = await transactionResponse.wait();
                toast.success('NFT has been minted successfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                console.log('receipt', receipt)
                resetFormData()
            } else {
                toast.error('Please connect the metamask to proceed', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setIsNftMintingLoading(false)

        } catch (error) {
            setIsNftMintingLoading(false)
            console.error("NFT MINTING ERROR ", error)
            toast.error('Oppz! Error while minting your NFT', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleMediaButton = (e) => {
        setFileType(e.target.value)

        if (e.target.value === "image") {
            setImageButtonState(true)
            setVideoButtonState(false)
            setMusicButtonState(false)
            set3dButtonState(false)
            setUploadFileType(imageTypes)
        } else if (e.target.value === "video") {
            setImageButtonState(false)
            setVideoButtonState(true)
            setMusicButtonState(false)
            set3dButtonState(false)
            setUploadFileType(videoTypes)
        } else if (e.target.value === "music") {
            setImageButtonState(false)
            setVideoButtonState(false)
            setMusicButtonState(true)
            set3dButtonState(false)
            setUploadFileType(audioTypes)
        } else if (e.target.value === "3d") {
            setImageButtonState(false)
            setVideoButtonState(false)
            setMusicButtonState(false)
            set3dButtonState(true)
            setUploadFileType(thrdTypes)
        }
    }

    const createMetaDataFile = (IPFSHash) => {

        const timestamp = DateTime.now();
        const { ts } = timestamp;
        const fileName = `ever-earn-metadata-${ts}.json`;

        const metadata = {
            name: fileName
        }

        const categoriesList = []
        if (selectedCategories && selectedCategories.length > 0) {
            selectedCategories.forEach(element => {
                categoriesList.push(element.name || '')
            });
        }

        const content = {
            name: name,
            description: description,
            image: "ipfs://" + IPFSHash,
            category: categoriesList ? categoriesList.toString() : '',
            fileType: filetype,
            attributes: [],
        }
        const payload = {
            pinataMetadata: metadata,
            pinataContent: content,
        }

        return payload;
    }

    const uploadMetaDataFile = async (metaDataJson) => {

        try {
            const endpoint = "https://api.pinata.cloud/pinning/pinJSONToIPFS";
            const options = {
                headers: {
                    pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
                    pinata_secret_api_key: process.env.REACT_APP_PINATA_SECRET_KEY
                }
            }
            const response = await axios.post(endpoint, metaDataJson, options);
            toast.success('Metadata file has been upload to the IPFS server', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return response.data;
        } catch (error) {
            console.error("metadata upload error", error);
            toast.error('Oppz! Something went wrong while uploading metadata file to IPFS server', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }

    const handleChange = async (file) => {

        if (file) {
            setFile(file)
            const nftImage = URL.createObjectURL(file)
            setNftImagePreview(nftImage)
        } else {
            setFile(null);
        }
    };

    const uploadFileToIPFS = async (file) => {
        const endpoint = 'https://api.pinata.cloud/pinning/pinFileToIPFS'
        const pinataAPIKey = process.env.REACT_APP_PINATA_API_KEY
        const pinataAPISecret = process.env.REACT_APP_PINATA_SECRET_KEY

        const dt = DateTime.now()
        const newFileName = dt.toMillis() + "_" + file.name
        let data = new FormData();
        data.append('file', file);

        const metadata = JSON.stringify({
            name: newFileName,
            keyvalues: {
                everEarnMinterFile: 'ever-earn-minter' + newFileName,
                originalFileName: file.name,
            }
        });

        data.append('pinataMetadata', metadata);
        const response = await axios.post(endpoint, data, {
            maxBodyLength: 'Infinity', //this is needed to prevent axios from erroring out with large files
            headers: {
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                pinata_api_key: pinataAPIKey,
                pinata_secret_api_key: pinataAPISecret
            }
        })

        if (response.status === 200) {
            toast.success('File has been upload to the IPFS server', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return response.data.IpfsHash

        } else {
            toast.error('Oppz!, something went wrong while uploading file to IPFS server ', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return null
        }
    }

    const onSelect = (e) => {
        setSelectedCategories(e)
    }

    return (
        <div id="layoutSidenav_content" className="mt-4 container-fluid">

            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-8 section-container">
                    <h4 className="text-white mt-2">Select file type</h4>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-between">
                                {imageButtonState ? <button type="button" className="btn btn-lg custom_button_active" value="image" onClick={e => handleMediaButton(e)}>Image</button> : <button type="button" className="btn btn-lg custom_button_deactive" value="image" onClick={e => handleMediaButton(e)}>Image</button>}
                                {videoButtonState ? <button type="button" className="btn btn-lg custom_button_active" value="video" onClick={e => handleMediaButton(e)}>Video</button> : <button type="button" className="btn btn-lg custom_button_deactive" value="video" onClick={e => handleMediaButton(e)}>Video</button>}
                                {musicButtonState ? <button type="button" className="btn btn-lg custom_button_active" value="music" onClick={e => handleMediaButton(e)}>Music</button> : <button type="button" className="btn btn-lg custom_button_deactive" value="music" onClick={e => handleMediaButton(e)}>Music</button>}
                                {thrdButtonState ? <button type="button" className="btn btn-lg custom_button_active" value="3d" onClick={e => handleMediaButton(e)}>3D</button> : <button type="button" className="btn btn-lg custom_button_deactive" value="3d" onClick={e => handleMediaButton(e)}>3D</button>}
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 d-flex col-md-6 col-lg-12 col-sm-12 justify-content-center custom_upload_area">
                        <FileUploader
                            classes="file-upload"
                            handleChange={handleChange}
                            name="file"
                            maxSize={30}
                            types={uploadFileType}
                        />
                    </div>

                    <form className="mt-4" onSubmit={handleSubmit}>
                        <div className="form-group col-lg-8 col-md-8 col-sm-12">
                            <label htmlFor="name" className="text-light custom_label pt-1 pl-2 pr-2">Name <small className="text-warning custom_small">*</small></label>
                            <input
                                type="text"
                                className="form-control custom_input text-light label-on-border"
                                id="name"
                                placeholder="Enter name"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                maxLength="24" />
                            <small className="text-right text-muted">{name.length}/24</small>
                        </div>

                        <div className="form-group mt-3  col-lg-8 col-md-8 col-sm-12">
                            <Multiselect
                                options={categories}
                                displayValue="name"
                                onSelect={e => onSelect(e)}
                                selectionLimit={3}
                                ref={multiSelectRef}
                                disablePreSelectedValues={true}
                                placeholder="Max 3 Categories"
                                style={{ chips: { background: "rgba(240, 185, 11, 0.5)", color: 'white' }, option: { background: '#1E2022' }, closeIcon: { color: 'black' } }}
                                showArrow
                            />
                        </div>

                        <div className="form-group mt-3 col-lg-12 col-md-8 col-sm-12">
                            <label htmlFor="description" className="text-light custom_label">Description <small className="text-warning custom_small">*</small></label>
                            <textarea className="form-control custom_input text-light label-on-border" id="description" rows="4" value={description} onChange={e => setDescription(e.target.value)} maxLength="140"></textarea>
                            <small className="text-right text-muted">{description.length}/140</small>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                {
                                    isNftMintingLoading ?
                                        (<div className="spinner-grow text-warning" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>) :
                                        (<button type="submit" className="btn btn-md btn-warning mx-auto mt-4 mb-4">Mint Your NFT</button>)
                                }
                            </div>
                        </div>

                    </form>

                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 section-container">
                    <div className="preview-container">
                        <h4 className="text-white mt-2">Preview</h4>
                        <div className="nft-image-preview">
                            <img
                                className="nft-preview-img card-img-top custom_nft_img"
                                alt={name}
                                src={nftImagePreview ? nftImagePreview : defaultPreviewImage}
                                onError={(event) => {
                                    event.target.onerror = "";
                                    event.target.src = defaultPreviewImage
                                    return true;
                                }}
                            />
                        </div>

                        <div className="nft-metadata-preview">
                            <div className="nft-name">
                                {name ? name : 'Name your cool NFT'}
                            </div>
                            <div className="nft-categories"></div>
                            <div className="nft-description">
                                {description ? description : 'Tell us more about your NFT'}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ToastContainer />
            <Footer />
        </div>
    )
}