import Footer from "../layout/footer"
import SideNav from "../layout/sidenav"

export default function everswap(){

  return(
    <SideNav>
      <iframe title="swapper" width="100%" height="675" src="https://www.flooz.trade/embedded/0x47d42E0e864ceFf8C4270F7b6E1f6F91Da45882C/?refId=v3gR0o&chainId=56&backgroundColor=transparent"> </iframe>
      <Footer/>
    </SideNav>
    )
}

     