import React, { useState, useEffect } from 'react'
import { useMoralis } from 'react-moralis';
import { configs } from '../../blockchain/web3.configs'
import MetaMaskOnboarding from '@metamask/onboarding';
import NFTContainer from '../NFTContainer';
import Footer from '../layout/footer';



export default function MyNft(props) {

    const { ScreenParent, nftTokenAddressParent, nftTokenIdParent } = props
    const { Moralis, isInitialized } = useMoralis()
    const [isLoading, setIsLoading] = useState(false)
    const [yourNftList, setYourNftList] = useState([])
    const [accounts, setAccounts] = useState([]);
    const [walletAddress, setWalletAddress] = useState(null)
    const { nftContractConfig } = configs
    const [offset, setOffset] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [isLoadMoreAvailable, setIsLoadMoreAvailable] = useState(true)
    const [cursor, setCursor] = useState('')


    const limit = 12

    const handleLoadMore = () => {

        if (cursor !== '') {
            setIsLoadMoreAvailable(true)
            let nextPage = currentPage + 1;
            let newOffset = nextPage * limit;
            setOffset(newOffset)
        } else {
            setIsLoadMoreAvailable(false)
        }

    }
    useEffect(() => {
        function handleNewAccounts(newAccounts) {
            setAccounts(newAccounts);
        }
        if (MetaMaskOnboarding.isMetaMaskInstalled()) {
            window.ethereum
                .request({ method: 'eth_requestAccounts' })
                .then(handleNewAccounts);
            window.ethereum.on('accountsChanged', handleNewAccounts);
            return () => {
                window.ethereum.off('accountsChanged', handleNewAccounts);
            };
        }
    }, []);

    useEffect(() => {
        if (accounts && accounts.length > 0) {
            setYourNftList([])
            setWalletAddress(accounts[0])
        }
    }, [accounts]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        if (isInitialized && walletAddress) {
            setCurrentPage(0)
            setOffset(0)
            setCursor(0)
            setYourNftList([])
            await fetchNFTData(walletAddress, offset, limit)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitialized, walletAddress])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        if (isInitialized && walletAddress) {
            await fetchNFTData(walletAddress, offset, limit)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitialized, offset, walletAddress])

    const fetchNFTData = async (walletAddress, offset, limit) => {
        try {

            setIsLoading(true)
            const options = { chain: nftContractConfig.moralisChainId, address: walletAddress, limit: limit, offset: offset }
            const userNFTs = await Moralis.Web3API.account.getNFTs(options)
            setCursor(userNFTs.cursor || '')
            setCurrentPage(userNFTs.page || 0)
            if (userNFTs.result && userNFTs.result.length > 0) {
                setYourNftList(yourNftList.concat(userNFTs.result))
            }
            console.log(userNFTs.result)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            setYourNftList([])
            console.error("MORALIS ERROR error while fetching your NFTS ", error)
        }
    }

    return (
        <div id="layoutSidenav_content" className="mt-4 container-fluid">
            <div className="form-row d-flex justify-content-between m-1 mb-5">
                <div className="col-md-10 col-sm-10">
                    <div className="form-group has-search">
                        <span className="fa fa-search form-control-feedback"></span>
                        <input
                            type="text"
                            className="form-control custom_input_search text-light"
                            placeholder="Enter Wallet ID"
                            value={walletAddress ? walletAddress : ''}
                        />
                    </div>
                </div>
                <div className="col-md-2 col-sm-2">
                    <select className="form-control custom_nft_select">
                        <option className="bg-dark">NFT</option>
                    </select>
                </div>
            </div>

            {/* {
                isLoading ?
                    (<div className="row">
                        <div className='col-sm-12 col-md-12 col-lg-12  d-flex justify-content-center'>
                            <div className="spinner-grow text-warning" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>) :
                    (<div className="row">
                        {
                            yourNftList.map((nft, index) => (
                                <NFTContainer data={nft} key={index} />
                            ))
                        }
                    </div>)
            } */}

            {
                isLoading ?
                    (<div className="row">
                        <div className='col-sm-12 col-md-12 col-lg-12  d-flex justify-content-center'>
                            <div className="spinner-grow text-warning" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>) :
                    (<div className="row">
                        {
                            yourNftList.length === 0 ? (
                                <div className='col-sm-12 col-md-12 col-lg-12  d-flex justify-content-center'>
                                    <div className='no-result-found'>
                                        Sorry no NFT(s) found
                                    </div>
                                </div>
                            ) :
                                (
                                    yourNftList.map((nft, index) => (
                                        <NFTContainer data={nft} key={index} setScreenParent={ScreenParent} setnftTokenAddressParent={nftTokenAddressParent} setnftTokenIdParent={nftTokenIdParent} />
                                    ))
                                )

                        }
                    </div>)
            }

            {
                yourNftList.length !== 0 && isLoading ?
                    (<div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-12  d-flex justify-content-center'>
                            <div className="spinner-grow text-warning" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>)
                    :
                    (<div></div>)
            }
            <div className="row justify-content-center mt-3 mb-3">
                {isLoadMoreAvailable && !isLoading && <button className="btn col-md-3 btn-lg text-center custom_load_more" onClick={handleLoadMore}>Load More NTFs</button>}
            </div>
            <Footer />

        </div>
    )
}