import React, {createContext, useState, useEffect} from 'react'

export const GlobalState = createContext()

export const DataProvider = ({children}) => {
    const [coinType, setCoinType] = useState("bsc")

    const state = {
        coinType: [coinType, setCoinType]
    }

    return (
        <GlobalState.Provider value={state}>
            {children}
        </GlobalState.Provider>
    )
}