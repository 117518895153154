import React, { useState } from "react";
import Footer from "../layout/footer";
import SideNav from "../layout/sidenav";

export default function Rewardscalc() {

    const [token,setTokens] = React.useState(0)
    const[cost,setCost]= React.useState(0)
    const[volume,setVolume] = React.useState(0)
    const[rewards,setRewards] = React.useState({rewards1:0,rewards2:0,
        rewards3:0,rewards4:0,rewards5:0,})


    function calculate(){
        // console.log(`1:${token} 2:${cost}3:${volume}`)
        
        const rewardPercent = .11;
        const supply = 35250000000;

        let rewards1 = (token / supply) * (volume * rewardPercent);
        let rewards2 = rewards1 * 7
        let rewards3 = rewards1 * 30
        let rewards4 = rewards1 * 365
        let rewards5 = rewards4 / cost * 100

        setRewards({rewards1,rewards2,rewards3,rewards4,rewards5})
    }


    return (
        <SideNav>
            <h1  style={{ color: 'white' }}  className="text-center">Rewards Calculator</h1>
            
            <div className="mt-4 mx-auto ">
              <div className="form-floating mb-3">
                <input className="form-control" type="text"
                  value={token}
                  onBlur={calculate}
                  onChange={(e) => {
                    setTokens(e.target.value);

                  }}
                  />
                <label>EverEarn Tokens Owned</label>
              </div>
              
            </div>
            <div className="mt-4 mx-auto">
              <div className="form-floating mb-3">
                <input className="form-control" type="text"
                  value={cost}
                  onBlur={calculate}
                  onChange={(e) => {
                    setCost(e.target.value);

                  }}
                  />
                <label>Cost of Tokens ($)</label>
              </div>
            </div>
            <div className="mt-4 mx-auto">
              <div className="form-floating mb-3">
                <input className="form-control" type="text"
                  value={volume}
                  onBlur={calculate}
                  onChange={(e) => {
                    setVolume(e.target.value);

                  }}
                  />
                <label >Daily Trade Volume ($)</label>
              </div>
            </div>
            <hr></hr>
            <div className="row">
            <div className=" col-md-3">
                <div className="card bg-warning  mb-4">
                  <div className="card-body">Daily ($)</div>
                  <div className="card-footer d-flex align-items-center justify-content-between">
                    <h4>{rewards.rewards1.toFixed(2)}</h4>
                  </div>
                </div>
              </div>
              <div className=" col-md-3">
                <div className="card bg-warning  mb-4">
                  <div className="card-body">Weekly ($)</div>
                  <div className="card-footer d-flex align-items-center justify-content-between">
                    <h4>{rewards.rewards2.toFixed(2)}</h4>
                  </div>
                </div>
              </div>
              <div className=" col-md-3">
                <div className="card bg-warning  mb-4">
                  <div className="card-body">Monthly ($):</div>
                  <div className="card-footer d-flex align-items-center justify-content-between">
                    <h4>{rewards.rewards3.toFixed(2)}</h4>
                  </div>
                </div>
              </div>
              <div className=" col-md-3">
                <div className="card bg-warning  mb-4">
                  <div className="card-body">Yearly ($)</div>
                  <div className="card-footer d-flex align-items-center justify-content-between">
                    <h4>{rewards.rewards4.toFixed(2)}</h4>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 mx-auto">
                <div className="card mb-4 bg-warning">
                  <div className="card-header">
                    {/* <i class="fas fa-chart-area me-1 mx-auto"></i> */}
                    APY %
                  </div>
                  <div className="card-body mx-auto">
                    {/* <canvas id="myAreaChart" width="100%" height="40"></canvas> */}
                    <h4>{rewards.rewards5.toFixed(2)}</h4>
                  </div>
                  {/* <button className="btn btn-dark btn-sm" ><h5>Claim Now </h5></button> */}
                </div>
              </div>
              </div>
            <Footer/>
        </SideNav>
    )
}
