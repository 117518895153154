import React, { useEffect, useState } from "react";
import { configs } from "../../blockchain/web3.configs";
import { ethers, utils } from "ethers";
import MetaMaskOnboarding from '@metamask/onboarding';
import { ToastContainer, toast } from 'react-toastify';
import Footer from "../layout/footer";
import { n4 } from "../../helpers/formatters";

import {
  everEarnAbi,
  distributorAbi,
  busdAbi,
  DISTRICONTRACT,
  EVEREARNCONTRACT,
  PROVIDER,
  BUSDCONTRACT
} from "../../constants/contracts";

function Staking() {

  //staking related variables
  const [isEverEarnTokenBalanceLoading, setIsEverEarnTokenBalanceLoading] = useState(false)
  const [everEarnTokenBalance, setEverEarnTokenBalance] = useState(0.00)

  const [isBUSDTokenBalance, setIsBUSDTokenBalance] = useState(false)
  const [busdTokenBalance, setBusdTokenBalance] = useState(0.00)

  const [isStakingDetailsLoading, setIsStakingDetailsLoading] = useState(false)
  const [stakingDetail, setStakingDetail] = useState(0.00)

  const [isTotalStakeLoading, setIsTotalStakeLoading] = useState(false)
  const [totalStaked, setTotalStaked] = useState(0.00)
  const [totalEarned, setTotalEarned] = useState(0);
  const [totalDistributed, setTotalDistributed] = useState(0);

  const [isStakingAPRDataLoading, setIsStakingAPRDataLoading] = useState(false)
  const [aprFor7Days, setAprFor7Days] = useState(0)
  const [aprFor14Days, setAprFor14Days] = useState(0)
  const [aprFor30Days, setAprFor30Days] = useState(0)
  const [aprFor60Days, setAprFor60Days] = useState(0)
  const [aprForUnlimitedDays, setAprForUnlimitedDays] = useState(0)

  const [isWithdrawAndExitLoading, setIsWithdrawAndExitLoading] = useState(false)
  const [isEmergencyWithdrawLoading, setIsEmergencyWithdrawLoading] = useState(false)
  const [isEverEarnStakingApprovalLoading, setIsEverEarnStakingApprovalLoading] = useState(false)
  const [isStakingProcessLoading, setIsStakingProcessLoading] = useState(false)
  const [stakingDuration, setStakingDuration] = useState(null)
  const [isActiveStakeButton, setIsActiveStakeButton] = useState(false)
  const [accounts, setAccounts] = React.useState([]);

  const [stakingAmount, setStakingAmount] = useState(0)
  const [everHold, setEverHold] = useState(0);
  const [busdHold, setBusdHold] = useState(0);
  const [unpaid, setUnpaidHold] = useState(0);

  const [selectPrentage, setSelectPercentage] = useState(null)

  let initialval = accounts[0];
  const [inpuVal, setInputVal] = useState(initialval);

  const provider = new ethers.providers.JsonRpcProvider(
    PROVIDER
  );

  let distriContract = new ethers.Contract(DISTRICONTRACT, distributorAbi, provider);

  let everEarnContract = new ethers.Contract(
    EVEREARNCONTRACT,
    everEarnAbi,
    provider
  );

  let busdContract = new ethers.Contract(BUSDCONTRACT, busdAbi, provider);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await getCurrentEverEarnTokenBalance()
    await getStakingDetailsBalance()
    await getStakeAPRData()
    await getTotalStakedData()
    await getBUSDTokenBalance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inpuVal])

  useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(handleNewAccounts);
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        window.ethereum.off('accountsChanged', handleNewAccounts);
      };
    }
  }, []);

  useEffect(() => {
    setInputVal(accounts[0]);
    console.log('input val set to metamask');
  }, [accounts[0]]);
  const handleSelectDate = (selectValue) => {
    setIsActiveStakeButton(false)
    setStakingDuration(selectValue)
  }

  const handleSelectRate = (value) => {

    if (value) {

      //get BUSD value for user
      const busdValue = busdTokenBalance
      const selectedPercentage = (busdValue / 100) * value
      setStakingAmount(selectedPercentage || 0.00)
    }
    setIsActiveStakeButton(false)
    setSelectPercentage(value)
  }

  async function getEverEarnBalanceof(address) {
    let EverEarnBal = await everEarnContract.balanceOf(address)
    return ethers.utils.formatEther(EverEarnBal);
  }

  async function getBusdBal(address) {
    let BusdBal = await busdContract.balanceOf(address)
    return ethers.utils.formatEther(BusdBal);
  }

  async function getTotalEarnings(address) {
    let tEarned = await distriContract.shares(address)
    return ethers.utils.formatEther(tEarned[2]);
  }

  async function getTotalBusdGiven() {
    return ethers.utils.formatEther(await distriContract.totalDistributed());
  }

  async function getUnpaidEarning(address) {

    let upaidEarnings = await distriContract.getUnpaidEarnings(address);
    return ethers.utils.formatEther(upaidEarnings);
  }

  const getCurrentEverEarnTokenBalance = async () => {

    try {
      setIsEverEarnTokenBalanceLoading(true)
      const { staking } = configs
      const everEarnTokenABI = JSON.parse(staking.erc20TokenContractAbi);
      const everEarnTokenAddress = staking.tokenAddress;
      //get current metamask provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const everEarnTokenInstance = new ethers.Contract(everEarnTokenAddress, everEarnTokenABI, provider);
      const tokeBalance = await everEarnTokenInstance.balanceOf(inpuVal)

      const tokenDecimals = await everEarnTokenInstance.decimals()
      const tokenDecimalInt = parseInt(tokenDecimals.toString())

      const tokenBalanceInt = parseInt(tokeBalance.toString())
      const actualTokenBalance = tokenBalanceInt / (10 ** tokenDecimalInt)
      setEverEarnTokenBalance(actualTokenBalance || 0.00)
      setIsEverEarnTokenBalanceLoading(false)
    } catch (error) {
      setIsEverEarnTokenBalanceLoading(false)
      setEverEarnTokenBalance(0.00)
      console.error("ERROR : while fetching EverEarn Token Balance : ", error)
    }
  }

  const getBUSDTokenBalance = async () => {
    try {
      setIsBUSDTokenBalance(true)
      const { staking } = configs
      const everEarnTokenABI = JSON.parse(staking.erc20TokenContractAbi);
      const busdTokenAddress = staking.busdTokenAddress;
      //get current metamask provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const everEarnTokenInstance = new ethers.Contract(busdTokenAddress, everEarnTokenABI, provider);
      const tokeBalance = await everEarnTokenInstance.balanceOf(inpuVal)

      const tokenDecimals = await everEarnTokenInstance.decimals()
      const tokenDecimalInt = parseInt(tokenDecimals.toString())

      const tokenBalanceInt = parseInt(tokeBalance.toString())
      const actualTokenBalance = tokenBalanceInt / (10 ** tokenDecimalInt)
      setBusdTokenBalance(actualTokenBalance || 0.00)
      setIsBUSDTokenBalance(false)
    } catch (error) {
      setIsBUSDTokenBalance(false)
      setBusdTokenBalance(0.00)
      console.error("ERROR : while fetching BUSD Token Balance : ", error)
    }
  }

  const getStakingDetailsBalance = async () => {

    try {
      setIsStakingDetailsLoading(true)
      const { staking } = configs
      const stakingContractABI = JSON.parse(staking.stackingContractAbi);
      const stakingContractAddress = staking.stakingContractAddress;
      //get current metamask provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider);
      const stakingDetail = await stakingContractInstance.stakeDetails(inpuVal)
      const stakingDetailBalance = parseInt(stakingDetail.toString())

      const actualStakingDetailBalance = stakingDetailBalance / (10 ** 18)
      setStakingDetail(actualStakingDetailBalance || 0.00)
      setIsStakingDetailsLoading(false)
    } catch (error) {
      setIsStakingDetailsLoading(false)
      setStakingDetail(0.00)
      console.error("ERROR : while fetching EverEarn Token Balance : ", error)
    }
  }

  const getTotalStakedData = async () => {

    try {
      setIsTotalStakeLoading(true)
      const { staking } = configs
      const stakingContractABI = JSON.parse(staking.stackingContractAbi);
      const stakingContractAddress = staking.stakingContractAddress;
      //get current metamask provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider);


      const totalStaked = await stakingContractInstance.totalStaking()
      const totalStakedInt = parseInt(totalStaked.toString())
      const actualTotalStaked = totalStakedInt / (10 ** 18)
      setTotalStaked(actualTotalStaked || 0.00)
      setIsTotalStakeLoading(false)
    } catch (error) {
      setIsTotalStakeLoading(false)
      setTotalStaked(0.00)
      console.error("ERROR : while fetching EverEarn staking APR and total stake data : ", error)
    }
  }

  const getStakeAPRData = async () => {

    try {
      setIsStakingAPRDataLoading(true)
      const { staking } = configs
      const stakingContractABI = JSON.parse(staking.stackingContractAbi);
      const stakingContractAddress = staking.stakingContractAddress;
      //get current metamask provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider);


      //get staking contract APR details
      const _7daysApr = await stakingContractInstance.calcApyMul100('7') / 100
      const _14daysApr = await stakingContractInstance.calcApyMul100('14') / 100
      const _30daysApr = await stakingContractInstance.calcApyMul100('30') / 100
      const _60daysApr = await stakingContractInstance.calcApyMul100('60') / 100
      const _unlimitedDaysApr = await stakingContractInstance.calcApyMul100('0') / 100

      setAprFor7Days(_7daysApr)
      setAprFor14Days(_14daysApr)
      setAprFor30Days(_30daysApr)
      setAprFor60Days(_60daysApr)
      setAprForUnlimitedDays(_unlimitedDaysApr)

      setIsStakingAPRDataLoading(false)
    } catch (error) {
      setIsStakingAPRDataLoading(false)
      setAprFor7Days(0)
      setAprFor30Days(0)
      setAprFor30Days(0)
      setAprFor60Days(0)
      setAprForUnlimitedDays(0)
      console.error("ERROR : while fetching EverEarn staking APR and total stake data : ", error)
    }
  }

  const handleWithdrawAndExit = async () => {

    try {
      setIsWithdrawAndExitLoading(true)
      //create the contract instance
      const { staking } = configs
      const stakingContractABI = JSON.parse(staking.stackingContractAbi);
      const stakingContractAddress = staking.stakingContractAddress;
      //get current metamask provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider);

      let signer = provider.getSigner(accounts[0]);
      const stakingContractInstanceWithSigner = stakingContractInstance.connect(signer);

      //call the contract function
      const withdrawAndExitReceipt = await stakingContractInstanceWithSigner.withdrawAndExit()
      const result = await withdrawAndExitReceipt.wait()

      toast.warn('Withdraw successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.log('withdrawAndExitReceipt', withdrawAndExitReceipt)
      console.log('result', result)
      setIsWithdrawAndExitLoading(false)
      getCurrentEverEarnTokenBalance()
      getStakingDetailsBalance()
      getTotalStakedData()
      getBUSDTokenBalance()

    } catch (error) {
      toast.warn(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error("ERROR: something went wrong while processing withdraw and exit ", error)
      setIsWithdrawAndExitLoading(false)
    }
  }

  const handleEmergencyWithdraw = async () => {
    try {
      setIsEmergencyWithdrawLoading(true)
      //create the contract instance
      const { staking } = configs
      const stakingContractABI = JSON.parse(staking.stackingContractAbi);
      const stakingContractAddress = staking.stakingContractAddress;
      //get current metamask provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider);

      let signer = provider.getSigner(accounts[0]);
      const stakingContractInstanceWithSigner = stakingContractInstance.connect(signer);

      //call the contract function
      const emergencyWithdrawReceipt = await stakingContractInstanceWithSigner.emergencyWithdraw()
      const result = await emergencyWithdrawReceipt.wait()

      toast.warn('Withdraw successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.log('withdrawAndExitReceipt', emergencyWithdrawReceipt)
      console.log('result', result)
      setIsEmergencyWithdrawLoading(false)
      getCurrentEverEarnTokenBalance()
      getStakingDetailsBalance()
      getTotalStakedData()
      getBUSDTokenBalance()

    } catch (error) {
      toast.warn(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error("ERROR: something went wrong while emergency withdraw ", error)
      setIsEmergencyWithdrawLoading(false)
    }
  }

  const handleApproveEverEarnForStaking = async () => {

    try {
      setIsEverEarnStakingApprovalLoading(true)
      //create the contract instance
      const { staking } = configs
      const erc20TokenABI = JSON.parse(staking.erc20TokenContractAbi);
      const stakingContractAddress = staking.stakingContractAddress;
      const busdTokenAddress = staking.busdTokenAddress;
      //get current metamask provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const erc20TokenInstance = new ethers.Contract(busdTokenAddress, erc20TokenABI, provider);

      console.log('staking duration', stakingDuration)
      console.log('staking amount', stakingAmount)

      if (!stakingDuration || stakingDuration === '') {
        toast.warn('Please select valid staking  period', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsEverEarnStakingApprovalLoading(false)
        return
      }

      if (!stakingAmount || stakingAmount <= 0) {
        toast.warn('Please enter valid staking BUSD amount', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsEverEarnStakingApprovalLoading(false)
        return
      }

      const stakeableAmountInWei = ethers.utils.parseEther(stakingAmount.toString())
      console.log('stakeableAmountInWei', stakeableAmountInWei.toString())

      let signer = provider.getSigner(accounts[0]);
      const erc20ContractInstanceWithSigner = erc20TokenInstance.connect(signer);
      const approvalReceipt = await erc20ContractInstanceWithSigner.approve(stakingContractAddress, stakeableAmountInWei.toString())
      const result = await approvalReceipt.wait()
      toast.warn('Approved successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log('approvalReceipt', approvalReceipt)
      console.log('result', result)
      setIsEverEarnStakingApprovalLoading(false)
      setIsActiveStakeButton(true)

    } catch (error) {
      toast.warn('ERROR: something went wrong while approval for staking' - error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error("ERROR: something went wrong while approval for staking ", error)
      setIsEverEarnStakingApprovalLoading(false)
      setIsActiveStakeButton(false)
    }

  }

  const handleStakeButtonClick = async () => {
    try {
      setIsStakingProcessLoading(true)
      //create the contract instance
      const { staking } = configs
      const stakingContractABI = JSON.parse(staking.stackingContractAbi);
      const stakingContractAddress = staking.stakingContractAddress;
      //get current metamask provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider);

      if (!stakingDuration || stakingDuration === '') {
        toast.warn('Please select valid staking  period', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsStakingProcessLoading(false)
        return
      }

      if (!stakingAmount || stakingAmount <= 0) {
        toast.warn('Please enter valid staking BUSD amount', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsStakingProcessLoading(false)
        return
      }

      const stakeableAmountInWei = ethers.utils.parseEther(stakingAmount.toString())
      let signer = provider.getSigner(accounts[0]);
      const stakingContractInstanceWithSigner = stakingContractInstance.connect(signer);
      const stakingReceipt = await stakingContractInstanceWithSigner.newStake(stakeableAmountInWei, stakingDuration)
      const result = await stakingReceipt.wait()
      console.log('approvalReceipt', stakingReceipt)
      console.log('result', result)
      toast.warn('Stake successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsStakingProcessLoading(false)
      getCurrentEverEarnTokenBalance()
      getStakingDetailsBalance()
      getTotalStakedData()
      getBUSDTokenBalance()

      setStakingAmount(0)
      setStakingDuration(null)
      setIsActiveStakeButton(false)

    } catch (error) {

      const errorMessage = error?.data.message || 'ERROR: something went wrong while proceeding staking'
      toast.warn(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error("ERROR: something went wrong while proceeding staking ", error)
      setIsStakingProcessLoading(false)
    }
  }

  const handleStakingAmountOnChange = (userInputAmount) => {
    setIsActiveStakeButton(false)
    const busdValue = busdTokenBalance
    if (busdValue <= 0) {

      console.log('You don\'t have enough tokens to stake');
      return
    }

    if (userInputAmount > busdValue) {
      console.log('Can not exceed current token balance of', busdValue);
      setSelectPercentage(busdValue)
      setStakingAmount(busdValue)
      return
    }
    setSelectPercentage(busdValue)
    setStakingAmount(parseInt(userInputAmount))
  }

  async function submit() {
    setEverHold(parseFloat(await getEverEarnBalanceof(inpuVal)).toFixed(2));
    setBusdHold(parseFloat(await getBusdBal(inpuVal)).toFixed(2));
    setTotalEarned(parseFloat(await getTotalEarnings(inpuVal)).toFixed(2));
    setTotalDistributed(parseFloat(await getTotalBusdGiven()).toFixed(2));
    setUnpaidHold(parseFloat(await getUnpaidEarning(inpuVal)).toFixed(2));
  };

  submit();


  return (


    <div id="layoutSidenav_content" className="mt-4 container-fluid">

      <div className="row">
        <div className="mt-4">
          <div className="form-floating mb-3">
            <input className="form-control" type="text"
              value={inpuVal}
              onChange={(e) => {
                setInputVal(e.target.value);
              }}
              onBlur={submit} />
            <label htmlFor="inputEmail">Wallet address</label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-3 col-md-3">
          <div className="card bg-warning  mb-4">
            <div className="card-body">EverEarn Balance</div>
            <div className="card-footer d-flex align-items-center justify-content-between">
              {
                isEverEarnTokenBalanceLoading ? (<div>Loading ...</div>)
                  :
                  (
                    <h4>{n4.format(everEarnTokenBalance || 0.0000)}</h4>
                  )
              }

            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-3">
          <div className="card bg-warning  mb-4">
            <div className="card-body">Stake Detail</div>
            <div className="card-footer d-flex align-items-center justify-content-between">
              {
                isStakingDetailsLoading ? (<div>Loading ...</div>)
                  :
                  (
                    <h4>{n4.format(stakingDetail || 0.0000)}</h4>
                  )
              }
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-3">
          <div className="card bg-warning  mb-4">
            <div className="card-body">Total Staked</div>
            <div className="card-footer d-flex align-items-center justify-content-between">
              {
                isTotalStakeLoading ?
                  (<div>Loading ...</div>) :
                  (<h4>{n4.format(totalStaked || 0.00)}</h4>)
              }

            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-3">
          <div className="card bg-warning  mb-4">
            <div className="card-body">BUSD Balance</div>
            <div className="card-footer d-flex align-items-center justify-content-between">
              {
                isBUSDTokenBalance ? (<div>Loading ...</div>)
                  :
                  (
                    <h4>{n4.format(busdTokenBalance || 0.0000)}</h4>
                  )
              }

            </div>
          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-xl-6 col-md-6 col-sm-6 col-6 mb-3">
          {
            isWithdrawAndExitLoading ? (
              <button
                type="button"
                className="btn btn-lg btn-warning col-xl-12 col-sm-12 col-12 small-text-mobile"
              >
                <div>Loading ...</div>
              </button>
            ) : (
              <button
                type="button"
                onClick={handleWithdrawAndExit}
                className="btn btn-lg btn-warning col-xl-12 col-sm-12 col-12 small-text-mobile"
              >
                Withdraw  Exit
              </button>
            )
          }
        </div>
        <div className="col-xl-6 col-md-6 col-sm-6 col-6 mb-3">
          {
            isEmergencyWithdrawLoading ? (
              <button
                type="button"
                className="btn btn-lg btn-warning col-xl-12 col-sm-12 col-12 small-text-mobile"
              >
                <div>Loading ...</div>
              </button>
            ) : (
              <button
                type="button"
                onClick={handleEmergencyWithdraw}
                className="btn btn-lg btn-warning col-xl-12 col-sm-12 col-12 small-text-mobile"
              >
                Emergency Withdraw
              </button>
            )
          }
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12">
          <div className="card bg-dark">
            <div className="card-body">
              <span className="text-muted">Lock duration and Rate</span><br />
              <span className="text-muted small">Learn more about buying tokens</span><br />

              <div class="card-deck mt-3 d-md-flex justify-content-between">
                <div className="card col-xl-2 col-md-2 mb-4 bg-warning" type="button">
                  <div className="card-header text-center" onClick={() => handleSelectDate(7)}>
                    <span className="custom-large-text">7</span><br />
                    <span>Days</span>
                  </div>
                  {
                    isStakingAPRDataLoading ?
                      (<div>Loading ...</div>) :
                      (
                        <button className="btn btn-dark btn-sm" onClick={() => handleSelectDate(7)} style={{ background: stakingDuration === 7 ? '#f7ba09' : '' }}><h5>{aprFor7Days}%</h5></button>
                      )
                  }


                </div>

                <div className="card col-xl-2 col-md-2 mb-4 bg-warning" type="button">
                  <div className="card-header text-center" onClick={() => handleSelectDate(14)}>
                    <span className="custom-large-text">14</span><br />
                    <span>Days</span>
                  </div>
                  {
                    isStakingAPRDataLoading ?
                      (<div>Loading ...</div>) :
                      (
                        <button className="btn btn-dark btn-sm" onClick={() => handleSelectDate(14)} style={{ background: stakingDuration === 14 ? '#f7ba09' : '' }}><h5>{aprFor14Days}%</h5></button>
                      )
                  }

                </div>

                <div className="card col-xl-2 col-md-2 mb-4 bg-warning" type="button">
                  <div className="card-header text-center" onClick={() => handleSelectDate(30)}>
                    <span className="custom-large-text">30</span><br />
                    <span>Days</span>
                  </div>
                  {
                    isStakingAPRDataLoading ?
                      (<div>Loading ...</div>) :
                      (
                        <button className="btn btn-dark btn-sm" onClick={() => handleSelectDate(30)} style={{ background: stakingDuration === 30 ? '#f7ba09' : '' }}><h5>{aprFor30Days}%</h5></button>
                      )
                  }

                </div>

                <div className="card col-xl-2 col-md-2 mb-4 bg-warning" type="button">
                  <div className="card-header text-center" onClick={() => handleSelectDate(60)}>
                    <span className="custom-large-text">60</span><br />
                    <span>Days</span>
                  </div>
                  {
                    isStakingAPRDataLoading ?
                      (<div>Loading ...</div>) :
                      (
                        <button className="btn btn-dark btn-sm" onClick={() => handleSelectDate(60)} style={{ background: stakingDuration === 60 ? '#f7ba09' : '' }}><h5>{aprFor60Days}%</h5></button>

                      )
                  }

                </div>

                <div className="card col-xl-2 col-md-2 mb-4 bg-warning" type="button">
                  <div className="card-header text-center" onClick={() => handleSelectDate(0)}>
                    <span className="custom-large-text">∞</span><br />
                    <span>Days</span>
                  </div>
                  {
                    isStakingAPRDataLoading ?
                      (<div>Loading ...</div>) :
                      (
                        <button className="btn btn-dark btn-sm" onClick={() => handleSelectDate(0)} style={{ background: stakingDuration === 0 ? '#f7ba09' : '' }}><h5>{aprForUnlimitedDays}%</h5></button>
                      )
                  }

                </div>
              </div>

              <div>
                <span className="text-muted">Lock duration and Rate</span>

                <div className="form-floating mb-3">
                  <input className="form-control" type="text" value={stakingAmount ? stakingAmount : 0} onChange={e => handleStakingAmountOnChange(e.target.value)} />
                  <label htmlFor="inputEmail">Enter Value</label>
                </div>

                <div className="d-flex justify-content-end">
                  <button type="button" className="btn btn-warning col-xl-1 col-sm-2 small-text-mobile custom-per-button-margin" onClick={() => handleSelectRate(25)}>25%</button>
                  <button type="button" className="btn btn-warning col-xl-1 col-sm-2 small-text-mobile custom-per-button-margin" onClick={() => handleSelectRate(50)}>50%</button>
                  <button type="button" className="btn btn-warning col-xl-1 col-sm-2 small-text-mobile custom-per-button-margin" onClick={() => handleSelectRate(75)}>75%</button>
                  <button type="button" className="btn btn-warning col-xl-1 col-sm-2 small-text-mobile custom-per-button-margin" onClick={() => handleSelectRate(100)}>100%</button>
                </div>

                <div className="mt-3">
                  {
                    isActiveStakeButton ? (
                      <div>
                        {
                          isStakingProcessLoading ? (
                            <button
                              type="button"
                              className="btn btn-lg btn-warning col-12"
                            >
                              <div>Loading ...</div>
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={handleStakeButtonClick}
                              className="btn btn-lg btn-warning col-12"
                            >
                              Stake BUSD
                            </button>
                          )
                        }
                      </div>
                    )
                      :
                      (
                        <div>
                          {
                            isEverEarnStakingApprovalLoading ? (
                              <button
                                type="button"
                                className="btn btn-lg btn-warning col-12"
                              >
                                <div>Loading ...</div>
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={handleApproveEverEarnForStaking}
                                className="btn btn-lg btn-warning col-12"
                              >
                                Approve EverEarn
                              </button>
                            )
                          }
                        </div>
                      )
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Staking
