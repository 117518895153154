import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { getSliceLongTxt } from '../helpers/formatters'
import noDataFoundImage from '../assets/images/no-data-found.png'
import { getFileTypeFromURI } from '../helpers/fileTypeDecoder';
import audioPlaceHolder from '../assets/images/audio-placeholder.jpeg'
import videoPlaceHolder from '../assets/images/video-placeholder.png'

export default function NFTContainer(props) {

  const { data, setScreenParent, setnftTokenAddressParent, setnftTokenIdParent } = props;
  const [nftImage, setNftImage] = useState("");
  const [nftTitle, setNftTitle] = useState("~");
  const [nftContentType, setNftContentType] = useState(null)

  useEffect(() => {
    renderMediaContainer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftContentType])


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (data.metadata) {
      const metadata = JSON.parse(data.metadata);
      let processedImageUrl = "";
      if (metadata.image && metadata.image.includes("ipfs://")) {
        const imageUrl = metadata.image.split("ipfs://");
        processedImageUrl = `https://h4g.mypinata.cloud/ipfs/${imageUrl[1]}`;
      } else {
        processedImageUrl = metadata.image;
      }
      setNftImage(processedImageUrl);
      setNftTitle(metadata.name || "~")
      const contentType = await getFileTypeFromURI(processedImageUrl)
      setNftContentType(contentType || '')
    } else {
      if (data.token_uri) {
        const response = await axios.get(data.token_uri);
        if (response.status === 200) {
          const metadata = response.data;
          let processedImageUrl = "";
          if (metadata.image && metadata.image.includes("ipfs://")) {
            const imageUrl = metadata.image.split("ipfs://");
            processedImageUrl = `https://h4g.mypinata.cloud/ipfs/${imageUrl[1]}`;
          } else {
            processedImageUrl = metadata.image;
          }
          setNftImage(processedImageUrl);
          setNftTitle(metadata.name || "~")
          const contentType = await getFileTypeFromURI(processedImageUrl)
          setNftContentType(contentType || '')
        }
      } else {
        setNftImage("");
        setNftTitle("~");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    setScreenParent('vote-details')
    setnftTokenAddressParent(data.token_address)
    setnftTokenIdParent(data.token_id)
  }

  const renderMediaContainer = () => {

    if (nftContentType) {
      if (nftContentType.includes('audio')) {
        setNftImage(audioPlaceHolder)
      }
      if (nftContentType.includes('video')) {
        setNftImage(videoPlaceHolder)
      }
    }
  }

  return (
    <div className="col-md-3 mb-3">
      <div className="card custom_card mb-2 h-100">
        <img
          className="card-img-top custom_nft_img"
          alt={nftTitle}
          src={nftImage ? nftImage : noDataFoundImage}
          onError={(event) => {
            event.target.onerror = "";
            event.target.src = noDataFoundImage
            return true;
          }}
          onClick={handleClick}
        />
        <div>
          <span className="text-light mt-2"><strong>{getSliceLongTxt(nftTitle, 50)}</strong></span><br />
          <small className="text-light custom_nft_small">Token ID # {data ? data.token_id : '~'}</small>
        </div>
      </div>
    </div>
  )
}