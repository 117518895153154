import React from "react";

const Footer = () => {
    return (        
        <footer className="py-4 bg-dark mt-auto">
          <div className="container-fluid px-4">
            <div className="d-flex align-items-center justify-content-between small">
              <div className="text-muted">Copyright © EverEarn 2022</div>
             
            </div>
          </div>
        </footer>
    )
}

export default Footer;