export const n6 = new Intl.NumberFormat("en-us", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 6
})

export const n8 = new Intl.NumberFormat("en-us", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 8
})

export const n4 = new Intl.NumberFormat("en-us", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 4
})

export const n2 = new Intl.NumberFormat("en-us", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
})

export const c2 = new Intl.NumberFormat("en-us", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

export const n12 = new Intl.NumberFormat("en-us", {
  style: "decimal",
  minimumFractionDigits: 12,
  maximumFractionDigits: 12
})

/**
 * Returns a string of form "abc...xyz"
 * @param {string} str string to string
 * @param {number} n number of chars to keep at front/end
 * @returns {string}
 */
export const getEllipsisTxt = (str, n = 6) => {
  if (str) {
    return `${str.slice(0, n)}...${str.slice(str.length - n)}`
  }
  return ""
}

export const getSliceLongTxt = (str, n = 18) => {
  if (str) {
    if (str.length <= n) {
      return str
    } else {
      return `${str.slice(0, n)}...`
    }

  }
  return ""
}
export const checkIsAdmin = (walletAdrs) => {

  const admin_ads = process.env.REACT_APP_ADMIN_ADRS.split(',')

  admin_ads.forEach(element => {
    if (element === walletAdrs) {
      console.log('admin');
      return 'admin'
    }
    else {
      console.log('not');
      return 'not admin'
    }
  });
}

export const tokenValue = (value, decimals) => (decimals ? value / Math.pow(10, decimals) : value)

/**
 * Return a formatted string with the symbol at the end
 * @param {number} value integer value
 * @param {number} decimals number of decimals
 * @param {string} symbol token symbol
 * @returns {string}
 */
export const tokenValueTxt = (value, decimals, symbol) => `${n2.format(tokenValue(value, decimals))} ${symbol}`

export const tokenPriceFormattedText = (value, symbol) => `${value} ${symbol}`
