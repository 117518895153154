import react from 'react';

const SideNav = (props) => {
    return (
        <div id="layoutSidenav_content" className="mt-4 container-fluid ">
            {props.children}
        </div>
    )
}

export default SideNav;