import React, { useEffect, useState } from 'react'
import Footer from '../layout/footer'
import noDataFoundImage from '../../assets/images/no-data-found.png'
import { useMoralis } from 'react-moralis';
import { configs } from '../../blockchain/web3.configs'
import axios from 'axios';
import { getFileTypeFromURI } from '../../helpers/fileTypeDecoder';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Player from 'griffith'
import { DateTime } from 'luxon'

function NftDetails(props) {

  const { nftTokenAddress, nftTokenId } = props
  const { Moralis, isInitialized } = useMoralis()
  const [nftName, setNftName] = useState('~')
  const [nftDescription, setNftDescription] = useState('')
  const [nftAssetUrl, setNftAssetUrl] = useState('')
  const [nftContentType, setNftContentType] = useState(null)
  const [nftMetadata, setNftMetadata] = useState(null)
  const [isNftDataLoading, setIsNftDataLoading] = useState(false)

  const renderMediaContainer = () => {

    if (nftContentType) {
      if (nftContentType.includes('image')) {
        return (
          <img
            alt={nftName ? nftName : ''}
            className="custom_nft_img my-auto"
            src={nftAssetUrl ? nftAssetUrl : noDataFoundImage}
            onError={(event) => {
              event.target.onerror = "";
              event.target.src = noDataFoundImage
              return true;
            }}
          />
        )
      }
      if (nftContentType.includes('audio')) {
        return (

          <div style={{ margin: '20px 0px' }}>
            <h3 style={{ color: '#FFFFFF' }}>Audio File Type</h3>
            <AudioPlayer
              autoPlay={false}
              src={nftAssetUrl}
              onPlay={e => console.log("onPlay")}
            />
          </div>
        )
      }
      if (nftContentType.includes('video')) {
        const sources = {
          hd: {
            play_url: nftAssetUrl,
          },
          sd: {
            play_url: nftAssetUrl,
          },
        }
        return (

          <div style={{ margin: '20px 0px', paddingBottom: '20px' }} >
            <h3 style={{ color: '#FFFFFF' }}>Video File Type</h3>
            <Player
              sources={sources}
            />
          </div>
        )
      }
    }
  }


  useEffect(() => {
    renderMediaContainer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftContentType])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {

    try {

      setIsNftDataLoading(true)
      const { nftContractConfig } = configs
      const pinataGatewayUrl = process.env.REACT_APP_PINATA_GATEWAY_URL
      if (isInitialized) {

        const options = { address: nftTokenAddress, token_id: nftTokenId, chain: nftContractConfig.moralisChainId }
        const tokenIdMetadata = await Moralis.Web3API.token.getTokenIdMetadata(options)
        setNftMetadata(tokenIdMetadata || null)

        if (tokenIdMetadata.metadata) {
          const metadata = JSON.parse(tokenIdMetadata.metadata)

          let processedImageUrl = ''
          if (metadata.image.includes('ipfs://')) {
            const imageUrl = metadata.image.split('ipfs://')
            processedImageUrl = pinataGatewayUrl + imageUrl[1]
          } else {
            processedImageUrl = metadata.image
          }

          const contentType = await getFileTypeFromURI(processedImageUrl)
          setNftContentType(contentType || '')
          setNftName(metadata.name || '~')
          setNftAssetUrl(processedImageUrl)
          setNftDescription(metadata.description || '')
        } else {
          if (tokenIdMetadata.token_uri) {
            const response = await axios.get(tokenIdMetadata.token_uri)
            if (response.status === 200) {
              const metadata = response.data
              let processedImageUrl = ''
              if (metadata.image.includes('ipfs://')) {
                const imageUrl = metadata.image.split('ipfs://')
                processedImageUrl = processedImageUrl = pinataGatewayUrl + imageUrl[1]
              } else {
                processedImageUrl = metadata.image
              }

              const contentType = await getFileTypeFromURI(processedImageUrl)
              setNftContentType(contentType || '')
              setNftName(metadata.name || '')
              setNftAssetUrl(processedImageUrl)
              setNftDescription(metadata.description || '')
            }
          } else {
            setNftName('~')
            setNftAssetUrl('')
            setNftDescription('')
            setNftMetadata(null)
          }
        }
      }
      setIsNftDataLoading(false)
    } catch (error) {
      console.error("ERROR : while fetching the token details : ".error)
      setIsNftDataLoading(false)
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftTokenAddress, nftTokenId])

  useEffect(() => {
    console.log(nftTokenAddress)
    console.log(nftTokenId)
  })

  return (
    <div id="layoutSidenav_content" className="mt-4 container-fluid" style={{ padding: '0px 30px' }}>

      {
        isNftDataLoading ?
          (<div className="d-flex justify-content-center" style={{ margin: '20px 0px' }}>
            <div className="spinner-grow text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>) :
          (<div className="d-lg-flex d-md-flex">
            <div className="col-md-6" style={{ padding: '0px 30px' }}>
              <div className="card custom_card mb-2 h-100" >
                {renderMediaContainer()}
              </div>
            </div>

            <div className="col-md-6 mt-1">
              <div className="d-flex">
                <p className="text-muted">Token created at: </p>
                <p className="text-light" style={{ paddingLeft: '5px', fontSize: '15px' }}>
                  {DateTime.fromISO(nftMetadata ? nftMetadata.synced_at : '').toFormat('yyyy LLL dd')}
                </p>
              </div>
              <div className="d-flex">
                <span className="badge badge-pill badge-secondary">{nftMetadata ? nftMetadata.contract_type : ''} {console.log(nftMetadata)}</span>
                <span className="badge badge-pill badge-secondary">TOKEN ID #{nftMetadata ? nftMetadata.token_id : ''}</span>
              </div>

              <h2 className="text-light mt-2">{nftName}</h2>

              <p className="text-light mt-2">
                {nftDescription}
              </p>
            </div>
          </div>)

      }

      <Footer />
    </div>
  )
}

export default NftDetails