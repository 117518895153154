/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import { RefreshCw } from 'react-feather'
import MetaMaskOnboarding from '@metamask/onboarding';
import Footer from "../layout/footer";
import { useMoralis } from 'react-moralis'
import { n2, n4, n6 } from '../../helpers/formatters'
import { GlobalState } from '../../GlobalState';
import DataTable from 'react-data-table-component';
import Avatar from 'react-avatar';


import { DateTime } from 'luxon'

function Holdings() {

    const [accounts, setAccounts] = useState([]);
    const [walletAddress, setWalletAddress] = useState(null)
    const { isInitialized, Moralis } = useMoralis()
    const [yourTokens, setYourTokens] = useState([])
    const [totalBalance, setTotalBalance] = useState(0.0)
    const [isTokenListLoading, setIsTokenListLoading] = useState(false)
    const [rawTokenResponse, setRawTokenResponse] = useState([])

    // to handle BSC, ETH, MATIC buttons
    const state = useContext(GlobalState)
    const [coinType, setCoinType] = state.coinType
    const [bscButtonClass, setBscButtonClass] = useState("select-button")
    const [ethButtonClass, setEthButtonClass] = useState("select-button-muted")
    const [maticButtonClass, setMaticButtonClass] = useState("select-button-muted")


    const columns = [
        {
            name: 'Name',
            sortable: true,
            width: "250px",
            selector: row => row.tokenData.name,
            cell: (row) => {
                return (
                    <div className='d-lg-flex flex-row token-metadata-container'>
                        <Avatar
                            size='40'
                            round="20px"
                            style={{ marginRight: '20px' }}
                            src={row ? row.tokenData.thumbnail : null}
                            name={row ? row.tokenData.name : ''} />
                        {/* <img src={binance} style={{ marginRight: '20px' }} alt={tokenData ? tokenData.name : ''} /> */}
                        <div className='token-name-container'>
                            <div>{row ? row.tokenData.name : ''}</div>
                            <div className="text-muted token-symbol">  {row ? row.tokenData.symbol : ''}</div>
                        </div>
                    </div>
                )
            }
        },
        {
            name: 'Amount',
            sortable: true,
            right: true,
            selector: row => row.actualTokenAmount,
            cell: (row) => {
                return (
                    <div className='row-token-balance'>{n6.format(row.actualTokenAmount || 0.000)} </div>
                )
            }
        },
        {
            name: 'Price',
            sortable: true,
            right: true,
            selector: row => row.currentTokenPrice,
            cell: (row) => {
                return (
                    <div>
                        ~ $ {n4.format(row.currentTokenPrice || 0.0000)}
                    </div>
                )
            }
        },
        {
            name: 'Total',
            sortable: true,
            right: true,
            selector: row => row.totalTokenBalance,
            cell: (row) => {
                return (
                    <div>
                        ~ $ {n4.format(row.totalTokenBalance || 0.0000)}
                    </div>
                )
            }
        },
        {
            name: '24 Hr P/L',
            sortable: true,
            width: "100px",
            selector: row => row.percentageLast24Hours,
            cell: (row) => {
                return (
                    <div className='row-token-balance'>
                        <div className={row.percentageLast24Hours && row.percentageLast24Hours < 0 ? 'custom_red' : 'custom_green'}>
                            {row.percentageLast24Hours.toFixed(2)} %
                        </div>
                    </div>
                )
            }
        },
        {
            name: '7 Days P/L',
            sortable: true,
            width: "100px",
            selector: row => row.percentageLast7Days,
            cell: (row) => {
                return (
                    <div className='row-token-balance'>
                        <div className={row.percentageLast7Days && row.percentageLast7Days < 0 ? 'custom_red' : 'custom_green'}>
                            {row.percentageLast7Days.toFixed(2)} %
                        </div>
                    </div>
                )
            }
        },
        {
            name: '30 Days P/L',
            sortable: true,
            width: "100px",
            selector: row => row.percentageLast30Days,
            cell: (row) => {
                return (
                    <div className='row-token-balance'>
                        <div className={row.percentageLast30Days && row.percentageLast30Days < 0 ? 'custom_red' : 'custom_green'}>
                            {row.percentageLast30Days.toFixed(2)} %
                        </div>
                    </div>
                )
            }
        },
    ];

    useEffect(() => {
        function handleNewAccounts(newAccounts) {
            setAccounts(newAccounts);
        }
        if (MetaMaskOnboarding.isMetaMaskInstalled()) {
            window.ethereum
                .request({ method: 'eth_requestAccounts' })
                .then(handleNewAccounts);
            window.ethereum.on('accountsChanged', handleNewAccounts);
            return () => {
                window.ethereum.off('accountsChanged', handleNewAccounts);
            };
        }

    }, []);

    useEffect(() => {
        window.localStorage.removeItem('tokenAmountList')
        window.localStorage.removeItem('tokenPriceList')
        window.localStorage.removeItem('tokenBalanceList')
    }, []);

    useEffect(() => {
        if (accounts && accounts.length > 0) {
            setWalletAddress(accounts[0])
        }
    }, [accounts]);

    useEffect(() => {
        if (yourTokens && yourTokens.length > 0) {
            window.localStorage.removeItem('userTokenList')
            window.localStorage.setItem('userTokenList', JSON.stringify(yourTokens))
        } else {
            window.localStorage.setItem('userTokenList', [])
        }
    }, [yourTokens]);

    useEffect(async () => {

        if (walletAddress && isInitialized) {
            try {
                setIsTokenListLoading(true)
                const past24HoursTime = DateTime.now().minus({ day: 1 }).toISODate()
                const past7DaysTime = DateTime.now().minus({ day: 7 }).toISODate()
                const past30DaysTime = DateTime.now().minus({ day: 30 }).toISODate()

                const dateObject = {
                    past24HoursTime,
                    past7DaysTime,
                    past30DaysTime
                }

                const params2 = { walletAddress: walletAddress, chain: coinType, dateObject: dateObject }
                const response = await Moralis.Cloud.run("getTokenPriceList", params2)
                setRawTokenResponse(response || [])
                setIsTokenListLoading(false)
            } catch (error) {
                console.error("ERROR error while fetching main token list", error)
                setRawTokenResponse([])
                setIsTokenListLoading(false)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitialized, walletAddress, coinType])


    useEffect(() => {
        if (rawTokenResponse && rawTokenResponse.length > 0) {

            const tableDataSet = []
            let tokenTotalAmount = 0.0
            rawTokenResponse.forEach(tokenData => {

                if (tokenData.status === 'fulfilled') {
                    //calculate actual token amount
                    const tokenMetadata = tokenData.value.tokenData
                    const tokenBalance = parseInt(tokenMetadata.balance || 0)
                    const tokenDecimals = parseInt(tokenMetadata.decimals || 1)
                    const actualTokenAmount = tokenBalance / (10 ** tokenDecimals)

                    //get current token price
                    const tokenPriceData = tokenData.value.priceData
                    const currentPrice = tokenPriceData.tokenPriceCurrent.usdPrice || 0.00

                    const totalTokenPrice = parseFloat(actualTokenAmount) * parseFloat(currentPrice)

                    tokenTotalAmount = tokenTotalAmount + totalTokenPrice

                    //last 24 hours pricing data
                    const last24HoursPrice = tokenPriceData.tokenPrice24HorsAgo.usdPrice || 0.00
                    const last7DaysPrice = tokenPriceData.tokenPrice7DaysAgo.usdPrice || 0.00
                    const last30DaysPrice = tokenPriceData.tokenPrice30DaysAgo.usdPrice || 0.00

                    //calculate price fractionation
                    const deltaChangeLast24Hours = parseFloat(currentPrice) - parseFloat(last24HoursPrice)
                    const fluctuationLst24Hours = deltaChangeLast24Hours / parseFloat(last24HoursPrice)
                    const percentageLast24Hours = fluctuationLst24Hours * 100

                    const deltaChangeLast7Days = parseFloat(currentPrice) - parseFloat(last7DaysPrice)
                    const fluctuationLst7days = deltaChangeLast7Days / parseFloat(last7DaysPrice)
                    const percentageLast7Days = fluctuationLst7days * 100

                    const deltaChangeLast30Days = parseFloat(currentPrice) - parseFloat(last30DaysPrice)
                    const fluctuationLst30days = deltaChangeLast30Days / parseFloat(last30DaysPrice)
                    const percentageLast30Days = fluctuationLst30days * 100

                    const payload = {
                        tokenData: tokenMetadata,
                        currentTokenPrice: currentPrice,
                        actualTokenAmount: actualTokenAmount,
                        totalTokenBalance: totalTokenPrice,
                        percentageLast24Hours: percentageLast24Hours,
                        percentageLast7Days: percentageLast7Days,
                        percentageLast30Days: percentageLast30Days

                    }
                    tableDataSet.push(payload)
                }

            })
            setYourTokens(tableDataSet)
            setTotalBalance(tokenTotalAmount)

        } else {
            setYourTokens([])
            setTotalBalance(0.00)
        }
    }, [rawTokenResponse])


    useEffect(() => {
        if (coinType === "bsc") {
            setCoinType("bsc")
            setBscButtonClass("select-button")
            setEthButtonClass("select-button-muted")
            setMaticButtonClass("select-button-muted")
        } else if (coinType === "eth") {
            setCoinType("eth")
            setBscButtonClass("select-button-muted")
            setEthButtonClass("select-button")
            setMaticButtonClass("select-button-muted")
        } else if (coinType === "matic") {
            setCoinType("matic")
            setBscButtonClass("select-button-muted")
            setEthButtonClass("select-button-muted")
            setMaticButtonClass("select-button")
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClick = (e) => {
        if (e.target.value === "bsc") {
            setCoinType("bsc")
            setBscButtonClass("select-button")
            setEthButtonClass("select-button-muted")
            setMaticButtonClass("select-button-muted")
        } else if (e.target.value === "eth") {
            setCoinType("eth")
            setBscButtonClass("select-button-muted")
            setEthButtonClass("select-button")
            setMaticButtonClass("select-button-muted")
        } else if (e.target.value === "matic") {
            setCoinType("matic")
            setBscButtonClass("select-button-muted")
            setEthButtonClass("select-button-muted")
            setMaticButtonClass("select-button")
        }
    }

    const customStyles = {
        rows: {
            style: {
                backgroundColor: '#161718',
                color: 'white',
                borderBottom: '#6b6767'
            },
        },
        headCells: {
            style: {
                backgroundColor: '#161718',
                color: 'white'
            },
        },
        cells: {
            style: {

            },
        },
    };


    return (
        <div id="layoutSidenav_content" className="mt-4 container-fluid">
            <div className="form-row d-flex justify-content-between m-1 mb-4">
                <div className="col-md-10 col-sm-10">
                    <div className="form-group has-search">
                        <span className="fa fa-search form-control-feedback"></span>
                        <input
                            type="text" value={walletAddress ? walletAddress : ''}
                            className="form-control custom_input_search text-light"
                            onChange={(e) => setWalletAddress(e.target.value)}
                        />
                    </div>
                </div>

                <div className="col-md-2 col-sm-2">
                    <select className="form-control custom_nft_select">
                        <option className="bg-dark">Holdings</option>
                    </select>
                </div>
            </div>

            <div className="row d-flex m-2">

                <div className="d-lg-flex container-fluid">
                    {
                        isTokenListLoading ?
                            (<div className="m-1 mb-4 col-md-6">
                                <div className="spinner-grow text-warning" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>

                            </div>)
                            :
                            (<h1 className="text-light col-md-6"> ~ $ {n2.format(totalBalance ? totalBalance : 0.00)} <RefreshCw color="#4F4F4F" /></h1>)
                    }

                    <div className="d-flex col-md-6 justify-content-end">
                        <button className={`btn btn-md text-center ${bscButtonClass}`} value="bsc" onClick={handleClick}>BSC</button>
                        <button className={`btn btn-md text-center ${ethButtonClass}`} value="eth" onClick={handleClick}>ETH</button>
                        <button className={`btn btn-md text-center ${maticButtonClass}`} value="matic" onClick={handleClick}>MATIC</button>
                    </div>
                </div>

            </div>

            <div className="container table-responsive">

                {
                    isTokenListLoading ?
                        (
                            <div className="form-row d-flex justify-content-center m-1 mb-4 token-loader">
                                <div className="spinner-grow text-warning" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <div className="loading-text">
                                    Please wait ... Fetching ERC 20 Token Details
                                </div>
                            </div>
                        ) : (
                            <div className='data-table'>
                                <DataTable
                                    columns={columns}
                                    data={yourTokens}
                                    customStyles={customStyles}
                                />
                            </div>
                        )

                }


            </div>

            <Footer />
        </div>
    )
}

export default Holdings
