import React, { useState,useEffect } from "react";
import Footer from "../layout/footer";
import SideNav from "../layout/sidenav";

export default function ToDo() {
    const [toDoList,setToDoList] = useState([null]);

    useEffect(() => {
        getData();
    },[])

    const getData=()=>{
        fetch('/data/list.json'    
        ,{
            headers:{ 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }    
        })
          .then(function(response){
            return response.json();
        })
            .then(function(myJson) {
            setToDoList(myJson)
        });
    }

    const handleLinkOpen = (link) => {
        window.open(link.link);
    };

    const list = 
        toDoList
            .map((listData) => { 
        return( 
            <div>
                {listData ?
                    <div key={listData.id} className="col-xl-3 mx-auto">
                            <div className="card mb-4 bg-warning card-footer">
                                    <button 
                                        className="btn btn-dark btn-md" 
                                        onClick={() => handleLinkOpen(listData)}
                                    >
                                        <h5>
                                            {listData.name}
                                        </h5>
                                    </button>
                        </div>
                    </div> 
                    : null
                }
            </div>)
    })

    return (
        <SideNav>
            <h1  style={{ color: 'white' }}  className="text-center">Your daily voting list</h1>
            <div>
             {list}
            </div>       
            <Footer/>
        </SideNav>
    )
}
