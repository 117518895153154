import React, { useState,useEffect } from "react";
import Footer from "./footer";
import SideNav from "./sidenav";

export default function Faq() {
    const [toDoList,setToDoList] = useState([null]);

    useEffect(() => {
        getData();
    },[])

    const getData=()=>{
        fetch('/data/faq.json'    
        ,{
            headers:{ 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'cache-control': 'no-cache'
            }    
        })
          .then(function(response){
            return response.json();
        })
            .then(function(myJson) {
            setToDoList(myJson)
        });
    }

    const handleLinkOpen = (link) => {
        window.open(link.link);
    };

    const list = 
        toDoList
            .map((listData) => { 
        return( 
            <div>
                {listData ?
                    <div key={listData.id} className="col-xl-6 mx-auto">
                        <div className="card mb-4 bg-warning">
                            <div className="card-body">
                                <h4>
                                    {listData.question}
                                </h4>
                            </div>
                            <div className="card-footer d-flex align-items-center justify-content-between">{listData.answer}</div>
                        </div>
                    </div> 
                    : null
                }
            </div>)
    })

    return (
        <SideNav>
            <h1  style={{ color: 'white' }}  className="text-center">Frequently Asked Questions</h1>
            <div>
             {list}
            </div>       
            <Footer/>
        </SideNav>
    )
}
