import React, { useEffect, useState } from "react";
import MetaMaskOnboarding from '@metamask/onboarding';
import { ethers, utils } from "ethers";
import imgsrc from "../../img/logoEver2.png";
import imgsrc2 from "../../img/busd.webp";
import Everswap from './everswap.js';
import Rewardscalc from "./rc.js";
import ToDo from "./todo.js";
import Faq from "../layout/faq";
import NftDetails from "./NftDetails";
import Staking from "./Staking";
import { ToastContainer, toast } from 'react-toastify';

import {
  everEarnAbi,
  distributorAbi,
  busdAbi,
  DISTRICONTRACT,
  EVEREARNCONTRACT,
  PROVIDER,
  BUSDCONTRACT
} from "../../constants/contracts";
import axios from "axios";
import { COINGECKO } from "../../constants/env";
import Dropdown from "react-bootstrap/Dropdown";
import Footer from "../layout/footer";
import SideNav from "../layout/sidenav";
import Nft from "./Nft";
import MyNft from "./MyNft";
import Holdings from "./Holdings";

import { configs } from "../../blockchain/web3.configs";
import { n4 } from "../../helpers/formatters";

const ONBOARD_TEXT = 'Click here to install MetaMask!';
const CONNECT_TEXT = 'Connect';
const CONNECTED_TEXT = 'Connected';

export default function Portfolio() {
  let [everEarnMarket, setEverEarnMarket] = useState([null]);
  let [everEarnData, setEverEarnData] = useState([null]);
  let [everEarnCurrentPrice, setEverEarnCurrentPrice] = useState('');
  let [everEarnVolume, setEverEarnVolume] = useState('');
  let [supportedCurrencies, setSupportedCurrencies] = useState([]);
  let [telegramCount, setTelegramCount] = useState(0);
  let [twitterCount, setTwitterCount] = useState(0);
  const [holdValue, setHoldValue] = useState(0);
  const [currentCurrency, setCurrentCurrency] = useState('');
  const [buttonText, setButtonText] = React.useState(ONBOARD_TEXT);
  const [isDisabled, setDisabled] = React.useState(false);
  const [accounts, setAccounts] = React.useState([]);
  const onboarding = React.useRef();
  const [everHold, setEverHold] = useState(0);
  const [busdHold, setBusdHold] = useState(0);
  const [unpaid, setUnpaidHold] = useState(0);
  const [totalEarned, setTotalEarned] = useState(0);
  const [totalDistributed, setTotalDistributed] = useState(0);
  const [nftTokenAddress, setnftTokenAddress] = useState("")
  const [nftTokenId, setnftTokenId] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      await axios.get(COINGECKO + `coins/everearn`)
        .then(function (response) {
          setEverEarnData(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    fetchData();
    const interval = setInterval(() => fetchData(), 1800000)//udpate the volume every 30 minutes
    return () => { clearInterval(interval); }
  }, []);

  useEffect(() => {
    if (currentCurrency === '') {
      setCurrentCurrency('usd')
    }
    if (everEarnData.market_data && currentCurrency !== '') {
      setEverEarnMarket(everEarnData.market_data.total_volume[currentCurrency]);
      setEverEarnCurrentPrice(everEarnData.market_data.current_price[currentCurrency]);
      setEverEarnVolume(everEarnData.tickers[0].volume)
    }
    if (everEarnData.market_data && currentCurrency !== '' && everHold !== 0) {
      setHoldValue(everHold * everEarnData.market_data.current_price[currentCurrency]);
    }
    if (everEarnData.community_data) {
      setTelegramCount(everEarnData.community_data.telegram_channel_user_count)
      setTwitterCount(everEarnData.community_data.twitter_followers)
    }
  }, [everEarnData]);

  useEffect(() => {
    const fetchData = async () => {
      await axios.get(COINGECKO + `/simple/supported_vs_currencies`)
        .then(function (response) {
          setSupportedCurrencies(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        setButtonText(CONNECTED_TEXT);
        setDisabled(true);
        onboarding.current.stopOnboarding();
      } else {
        setButtonText(CONNECT_TEXT);
        setDisabled(false);
      }
    }
  }, [accounts]);

  useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(handleNewAccounts);
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        window.ethereum.off('accountsChanged', handleNewAccounts);
      };
    }
  }, []);

  useEffect(() => {
    setInputVal(accounts[0]);
    console.log('input val set to metamask');
  }, [accounts[0]]);

  const onClick = () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((newAccounts) => setAccounts(newAccounts));
    } else {
      onboarding.current.startOnboarding();
    }
  };



  const handleCurrencyClick = (currency, i) => {
    setCurrentCurrency(currency);
    setEverEarnCurrentPrice(everEarnData.market_data.current_price[currency]);
    setEverEarnMarket(everEarnData.market_data.total_volume[currency]); setHoldValue(everHold * everEarnData.market_data.current_price[currency]);
  }

  const [screen, setScreen] = useState('dashboard');
  let initialval = accounts[0];

  const [inpuVal, setInputVal] = useState(initialval);

  const provider = new ethers.providers.JsonRpcProvider(
    PROVIDER
  );

  let distriContract = new ethers.Contract(DISTRICONTRACT, distributorAbi, provider);

  let busdContract = new ethers.Contract(BUSDCONTRACT, busdAbi, provider);

  let everEarnContract = new ethers.Contract(
    EVEREARNCONTRACT,
    everEarnAbi,
    provider
  );

  const currencyList =
    supportedCurrencies
      .sort()
      .map((currency, i) => {
        return (
          <div className="p-sm-1">
            <div className="card bg-warning align-items-center">
              <Dropdown.Item key={i} onClick={() => handleCurrencyClick(currency, i)}>
                {currency}
              </Dropdown.Item>
            </div>
          </div>
        )
      })

  async function getEverEarnBalanceof(address) {
    let EverEarnBal = await everEarnContract.balanceOf(address)
    return ethers.utils.formatEther(EverEarnBal);
  }

  async function getBusdBal(address) {
    let BusdBal = await busdContract.balanceOf(address)
    return ethers.utils.formatEther(BusdBal);
  }

  async function getUnpaidEarning(address) {

    let upaidEarnings = await distriContract.getUnpaidEarnings(address);
    return ethers.utils.formatEther(upaidEarnings);
  }

  async function getTotalBusdGiven() {
    return ethers.utils.formatEther(await distriContract.totalDistributed());
  }

  async function getTotalEarnings(address) {
    let tEarned = await distriContract.shares(address)
    return ethers.utils.formatEther(tEarned[2]);
  }

  async function claim() {
    if (accounts.length > 0) {

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      let signer = provider.getSigner(accounts[0]);
      let contractWithSigner = distriContract.connect(signer);
      let transactionResponse = contractWithSigner.claimDividend();
      transactionResponse.then((pro) => {
        alert(`Transaction sent with hash : ${pro.hash}`)
      })
    } else {
      alert('Connect to metamask')
    }
  }

  async function submit() {
    setEverHold(parseFloat(await getEverEarnBalanceof(inpuVal)).toFixed(2));
    setBusdHold(parseFloat(await getBusdBal(inpuVal)).toFixed(2));
    setTotalEarned(parseFloat(await getTotalEarnings(inpuVal)).toFixed(2));
    setTotalDistributed(parseFloat(await getTotalBusdGiven()).toFixed(2));
    setUnpaidHold(parseFloat(await getUnpaidEarning(inpuVal)).toFixed(2));
  };

  submit();

  function renderElement() {
    if (screen === 'dashboard')
      return (
        <SideNav>
          <main>
            <div className="container-fluid px-4">
              <div className="row">
                <div className="mt-4 mx-auto">
                  <div className="form-floating mb-3">
                    <input className="form-control" type="text"
                      value={inpuVal}
                      onChange={(e) => {
                        setInputVal(e.target.value);
                      }}
                      onBlur={submit} />
                    <label htmlFor="inputEmail">Wallet address</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-md-6">
                    <div className="card bg-warning  mb-4">
                      <div className="card-body">EverEarn Holdings:</div>
                      <div className="card-footer d-flex align-items-center justify-content-between">
                        <h4>{everHold}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="card bg-warning  mb-4">
                      <div className="card-body">Busd Holdings:</div>
                      <div className="card-footer d-flex align-items-center justify-content-between">
                        <h4>{busdHold}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="card bg-warning  mb-4">
                      <div className="card-body">Your total Busd Earnings:</div>
                      <div className="card-footer d-flex align-items-center justify-content-between">
                        <h4>{totalEarned}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="card bg-warning  mb-4">
                      <div className="card-body"> Total BUSD Distributed:</div>
                      <div className="card-footer d-flex align-items-center justify-content-between">
                        <h4>{totalDistributed}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-md-6">
                    <div className="card bg-warning  mb-4">
                      <div className="card-body"> EverEarn Value ({currentCurrency}):</div>
                      <div className="card-footer d-flex align-items-center justify-content-between">
                        <h4>{holdValue}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="card mb-4 bg-warning">
                      <div className="card-header">
                        {/* <i className="fas fa-chart-area me-1 mx-auto"></i> */}
                        Rewards not claimed (USD):
                      </div>
                      <div className="card-body mx-auto">
                        {/* <canvas id="myAreaChart" width="100%" height="40"></canvas> */}
                        <h4>{unpaid}</h4>
                      </div>
                      <button className="btn btn-dark btn-sm" onClick={claim}><h5>Claim Now </h5></button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="dark"
                      id="dropdown-basic"
                    >
                      {currentCurrency ? currentCurrency : 'usd'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {currencyList}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-md-6">
                    <div className="card bg-warning  mb-4">
                      <div className="card-body">Market Volume ({currentCurrency}):</div>
                      <div className="card-footer d-flex align-items-center justify-content-between">
                        <h4>{everEarnMarket ? everEarnMarket : '0.00'}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="card bg-warning  mb-4">
                      <div className="card-body">Current Price ({currentCurrency}):</div>
                      <div className="card-footer d-flex align-items-center justify-content-between">
                        <h4>{everEarnCurrentPrice ? everEarnCurrentPrice : '0.00'}</h4>
                      </div>
                    </div>
                  </div>
                  {/*<div className="col-xl-3 col-md-6">
                    <div className="card bg-warning  mb-4">
                      <div className="card-body">Volume:</div>
                      <div className="card-footer d-flex align-items-center justify-content-between">
                        <h4>{everEarnVolume ? everEarnVolume : '0.00'}</h4>
                      </div>
                    </div>
                    </div>*/}
                </div>

                {/*<div className="row">
                  <div className="col-xl-3 col-md-6">
                    <div className="card bg-warning  mb-4">
                      <div className="card-body">Community</div>
                      <div className="card-footer d-flex align-items-center justify-content-between">
                        <ul className="list-unstyled">
                          <li>
                            Telegram Channel: {telegramCount ? telegramCount : 0}
                          </li>
                          <li>
                            Twitter Followers: {twitterCount ? twitterCount : 0}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  </div>*/}
                <br></br>  <br></br>
                <div className="mb-4 card" style={{ padding: "0px" }}>
                  <div className="card-body bg-dark text-white">
                    <p className="mb-0 text-center">
                      <a href="#" className="pull-left me-2"><img style={{ maxWidth: "35px" }} src={imgsrc}></img></a>
                      <strong>EverEarn Contract: </strong>{EVEREARNCONTRACT}
                      <br></br>  <br></br>
                      <a href="#" className="pull-left me-2"><img style={{ maxWidth: "35px" }} src={imgsrc2}></img></a>
                      <strong> Busd Contract:</strong>  {BUSDCONTRACT}
                      <br></br>  <br></br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </SideNav>)
    else
      if (screen === 'everswap') {
        return (<Everswap />)
      }
      else if (screen === 'rc') {
        return (<Rewardscalc />)
      }
      else if (screen === 'todo') {
        return (<ToDo />)
      }
      else if (screen === 'faq') {
        return (<Faq />)
      }
      else if (screen === 'nft') {
        return (<Nft />)
      }
      else if (screen === 'holdings') {
        return (<Holdings />)
      }
      else if (screen === 'mynft') {
        return (<MyNft ScreenParent={setScreen} nftTokenAddressParent={setnftTokenAddress} nftTokenIdParent={setnftTokenId} />)
      } else if (screen === 'vote-details') {
        return (<NftDetails nftTokenAddress={nftTokenAddress} nftTokenId={nftTokenId} />)
      } else if (screen === 'staking') {
        return (<Staking />)
      }
  }

  return (
    <React.Fragment>
      <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
        {/* Navbar Brand*/}
        <a href="#" className="pull-left mx-3"><img style={{ maxWidth: "35px" }} src={imgsrc}></img></a>
        <a className="navbar-brand ps-3" href="index.html">EverEarn</a>
        {/* Sidebar Toggle*/}
        <button className="btn btn-link btn-sm  order-lg-0 me-4 me-lg-0 " id="sidebarToggle" href="#!"><i className="fas fa-bars" /></button>
        {/* Navbar Search*/}
        <div className=" d-md-inline-block form-inline  ms-auto me-3  my-2 my-md-0">
          <div className="input-group">
            <button className="btn btn-warning" id="btnNavbarSearch" type="button" disabled={isDisabled} onClick={onClick}> {buttonText}</button>
          </div>
        </div>
      </nav>

      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div className="sb-sidenav-menu">
              <div className="nav">
                <div className="sb-sidenav-menu-heading">Core</div>
                <a className="nav-link" onClick={() => { setScreen('dashboard') }}>
                  <div className="sb-nav-link-icon" ><i className="fas fa-tachometer-alt" /></div>
                  Dashboard
                </a>
                <a className="nav-link" onClick={() => { setScreen('todo') }} >
                  <div className="sb-nav-link-icon"><i className="fas fa-clipboard-check"></i></div>
                  To Do List
                </a>
                <a className="nav-link" onClick={() => { setScreen('faq') }} >
                  <div className="sb-nav-link-icon"><i className="fa fa-question-circle"></i></div>
                  FAQ
                </a>
                <div className="sb-sidenav-menu-heading">Other Links</div>
                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                  <div className="sb-nav-link-icon"><i className="fas fa-hashtag"></i></div>
                  Socials
                  <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down" /></div>
                </a>
                <div className="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                  <nav className="sb-sidenav-menu-nested nav">
                    <a className="nav-link" href="https://twitter.com/theEverEarn" target="_blank" rel="noreferrer">Twitter</a>
                    <a className="nav-link" href="https://www.instagram.com/everearnofficial/" target="_blank" rel="noreferrer">Instagram</a>
                    <a className="nav-link" href="https://github.com/EverEarn/EverEarnProject" target="_blank" rel="noreferrer">Github</a>
                    <a className="nav-link" href="https://www.tiktok.com/@everearnofficial" target="_blank" rel="noreferrer">Tiktok</a>
                    <a className="nav-link" href="https://www.facebook.com/EverEarn-106688511903307/" target="_blank" rel="noreferrer">Facebook</a>
                    <a className="nav-link" href="https://www.youtube.com/channel/UCFx-WBuUIYAzpRq9TG4eMuw" target="_blank" rel="noreferrer">Youtube</a>
                    <a className="nav-link" href="https://www.reddit.com/r/EverEarn/" target="_blank" rel="noreferrer" >Reddit</a>
                    <a className="nav-link" href="https://everearn.medium.com/" target="_blank" rel="noreferrer">Medium</a>
                  </nav>
                </div>
                <a className="nav-link collapsed"  >
                  <div className="sb-nav-link-icon"><i className="fab fa-chrome"></i></div>
                  <a className="" href="https://everearn.net" style={{ textDecoration: 'none', color: '#6c757d' }} target="_blank" rel="noreferrer">Website</a>
                </a>
                <div className="sb-sidenav-menu-heading">Addons</div>
                <a className="nav-link" onClick={() => { setScreen('everswap') }} >
                  <div className="sb-nav-link-icon"><i className="fas fa-sync-alt"></i></div>
                  EverSwap
                </a>

                <a className="nav-link" onClick={() => { setScreen('rc') }} >
                  <div className="sb-nav-link-icon"><i className="fas fa-calculator"></i></div>
                  Rewards Calculator
                </a>
                {/*<a className="nav-link" onClick={() => { setScreen('holdings') }} >
                  <div className="sb-nav-link-icon"><i className="fas fa-calculator"></i></div>
                  Holdings
                </a>*/}
                <a className="nav-link" onClick={() => { setScreen('nft') }} >
                  <div className="sb-nav-link-icon"><i className="fas fa-image"></i></div>
                  NFT Minting
                </a>

                <a className="nav-link" onClick={() => { setScreen('staking') }} >
                  <div className="sb-nav-link-icon"><i className="fas fa-calculator"></i></div>
                  Staking
                </a>

                <a className="nav-link" onClick={() => { setScreen('mynft') }} >
                  <div className="sb-nav-link-icon"><i className="fas  fa-images"></i></div>
                  My NFT
                </a>

                <a className="nav-link" onClick={() => { setScreen('holdings') }} >
                  <div className="sb-nav-link-icon"><i className="fa fa-archive"></i></div>
                  Holdings
                </a>
              </div>
            </div>
          </nav>
        </div>

        {renderElement()}

      </div>
      here<p />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  );
}